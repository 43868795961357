import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PAGINA_LISTA_CURSOS } from './rutas';
import { inject, observer,  } from "mobx-react"


const RutaPublica = (props) => {
  const {store, component: Comp, ...resto} = props

  return <Route {...resto} component={(props)=>(
    resto.restricted ? 
      ( store.usuario.uid != '' ? 
        <Redirect to={PAGINA_LISTA_CURSOS}/>
        : 
        <Comp {...resto} />
      )
    :
      <Comp {...props} />
  )}/> 
}

export default inject('store')(observer(RutaPublica))


