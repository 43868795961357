import  React, {Component}   from 'react';

import { css } from 'react-emotion';
// First way to import

// ver http://www.davidhu.io/react-spinners/
import { ClipLoader, CircleLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Cargando extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    return (
      <div className="row">

    <div className="col">
    </div>

    <div className="col">
      <div className='sweet-loading'>
        <CircleLoader
          className={override}
          sizeUnit={"px"}
          size={150}
          color={'#123abc'}
          loading={this.state.loading}
        />
      </div> 
    </div>

    <div className="col">
    </div>

  </div>
    )
  }
}

export default Cargando