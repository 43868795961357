import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

// Sin estas 3 lineas no formatea bien mdbreact
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import makeInspectable from "mobx-devtools-mst";
import {observer, inject, Provider,  } from "mobx-react"
import { store, iniciarApp }  from './store/iniciarApp'
 
import {inicializarFirebase, authLogin} from './firebase/config'
import './estilos/Estilos.css'
// import App from './layout/App';


import { Switch, Route, BrowserRouter } from 'react-router-dom'
import * as rutas from './rutas/rutas'

import RutaPublica from './rutas/RutaPublica'
import RutaPrivada from './rutas/RutaPrivada' 

import Layout from './layout/Layout'
import PaginaInicio from './paginas/PaginaInicio'
import PaginaCurso from './entidades/curso/PaginaCurso'
import PaginaCursos from './entidades/curso/PaginaCursos'
import PaginaDraft from './paginas/PaginaDraft' 


// import PaginaInformacion from './entidades/curso/PaginaInformacion'
import PaginaInformacion from './entidades/curso/PaginaInformacion'

import PaginaSoporte from './entidades/soporte/PaginaSoporte'
import PaginaContactenos from './paginas/PaginaContactenos' 
import PaginaLogin from './entidades/usuario/PaginaLogin'

inicializarFirebase()
iniciarApp()

class DebugRouter extends BrowserRouter {
  constructor(props){
    super(props);
    console.log('initial history is: ', JSON.stringify(this.history, null,2))
    this.history.listen((location, action)=>{
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      )
      console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
    });
  }
}

ReactDOM.render(
  
  <Provider store={store}>
    <BrowserRouter>
    {/* <DebugRouter> */}
      <Layout>
        <Switch>

          <Route exact path ='/' component={PaginaInicio} />
          <Route path = {rutas.PAGINA_DRAFT} component = {PaginaDraft} />

          <Route path = {rutas.PAGINA_INICIO} component= {PaginaInicio} />
          

          <RutaPrivada path = {rutas.PAGINA_SOPORTE} component = {PaginaSoporte} />

          <Route path = {rutas.PAGINA_CONTACTENOS} component = {PaginaContactenos} />

          <Route path = {rutas.PAGINA_LOGIN} component = {PaginaLogin} />
          
          <RutaPrivada path = {`${rutas.PAGINA_INFORMACION}/:codigoCurso`} component ={PaginaInformacion} />

          <RutaPrivada path = {`${rutas.PAGINA_CURSO}/:codigoCurso`} component ={PaginaCurso} />

          <RutaPrivada path = {rutas.PAGINA_LISTA_CURSOS} component ={PaginaCursos} />

          {/* <Route path = {rutas.PAGINA_LISTA_CURSOS} component={PaginaCursos}/> */}

        </Switch>
      </Layout>
    {/* </DebugRouter> */}

    </BrowserRouter>
  </Provider>
,document.getElementById('root'));


makeInspectable(store);

registerServiceWorker();