/* 
modelo de campo de curso
*/
import { types, getSnapshot } from 'mobx-state-tree'

const ModeloPasswordUsuario = types
.model('ModeloPasswordUsuario', {
  icon: 'user-secret',
  label: 'Password', 
  name: 'password',
  type: 'password',
  value: '',
  error: '',
  errorRequerido: 'Campo no debe quedar vacío',
})

.actions (self => ({
  setValor(value) {
    self.value = value
    self.error = ''
  },

  validar() {
    if (self.value == '') {
      self.error = self.errorRequerido
      return
    }

    if (self.value.length < 2 || self.value.length > 20) {
      self.error = self.errorLongitud
      return
    }
  }

}))

.views (self => ({ 
  get getPropiedades () {
    return  getSnapshot(self)
  }

})) //fin views

export default  ModeloPasswordUsuario