/* 
Estoy reformateando PaginaInformacion.
El cambio en el diseño es para separar la informacion de una curso cerrada de una abierta.

La estuctura va a ser la siguiente
  PaginaInformacion
    Encabezado (no uso componente separado)

    ComponenteCursoAbierto O ComponenteCursoCerrado (componentes separados)
*/


import {
  Button,
  CardImage, 
  CardBody, 
  CardTitle, 
  CardText, 
  Card, 
} from 'mdbreact'

import  React, {Component}   from 'react';
import { inject, observer,  } from "mobx-react";
import TituloPagina from '../../layout/TituloPagina';
import {PAGINA_CURSO} from '../../rutas/rutas';
import { Link } from 'react-router-dom';
import ComponenteCursoAbierto from './ComponenteIniciarExamen'
import ComponenteExamenFinalizado from './ComponenteExamenFinalizado'
import ComponenteIniciarExamen from './ComponenteIniciarExamen'  
import Cargando from '../../layout/Cargando'

class PaginaInformacion extends Component {

  render () {

    let s = null
    // si se estan cargando datos se despliega aviso de carga
    if (this.props.store.cargando) {
      return (<Cargando/>)
    } else {
      s = this.props.store
    }

    // si ya cargo datos se define el curso seleccionado con base en el codigo de curso enviado como props de PaginaCursos
    if (!s.cargando) {
      const cursoIndex = s.getCursoIndex(this.props.match.params.codigoCurso)
      s.setCursoSeleccionadoIndex(cursoIndex)
      }  

    // obtiene datos de la curso con base en el param enviado como props por PaginaCursos
    const curso = s.getCursoSnapshot(this.props.match.params.codigoCurso)

    const { 
      nombreCurso,
      imagenCurso,
      codigoCurso,
      descripcionCurso,  
      instrucciones,
      nombreInstructor,
      correoInstructor,
    } = curso
    
    const fechaInicioClase = s.getFechaInicioClase()
    const fechafinClase = s.getFechaFinClase()
    const fechaInicioExamen = s.getFechaInicioExamen()
    const fechaFinExamen = s.getFechaFinExamen()
    const estatusExamen = s.getEstatusExamen()
    const estatusPeriodoExamen = s.getEstatusPeriodoExamen()

    return (

      <Card style={cardEstilo}>
        <CardImage 
          className="img-fluid" 
          src={imagenCurso}
          style={{margin:'30px'}}
        />
        <CardBody>

          <h3 mx-auto>
            {`${nombreCurso} (${codigoCurso})`} 
          </h3>

          <p style={{margin: '0px',}}>
            {`Instructor: ${nombreInstructor} (${correoInstructor})`}
          </p>

          <hr/>

          {/* El periodo de examen ya cerro y el estudiante lo realizo satisfactoriamente */}
          { estatusPeriodoExamen === 'CERRADO' && estatusExamen === 'FINALIZADO'
            ? <ComponenteExamenFinalizado/>
            : null
          }

          {/* El periodo de examen no ha iniciado y el estudiante tampoco ha iniciado la evaluacion */}
          {estatusPeriodoExamen === 'NO_INICIADO' && estatusExamen === 'NO_INICIADO' 
          ? <p> {`Periodo de evaluacion: del ${fechaInicioExamen} al ${fechaFinExamen}`} </p>
            : null
          }

          {/* El periodo de examen está abierto y el estudiante no ha iniciado la evaluacion aún */}
          {estatusPeriodoExamen === 'ABIERTO' && estatusExamen === 'NO_INICIADO' 
            ? <ComponenteIniciarExamen/>
            : null
          }

          { /* El periodo de examen está abierto y el estudiante  ya inicio la evaluacion pero no la termino*/ }
          {estatusPeriodoExamen === 'ABIERTO' && estatusExamen === 'EN_EJECUCION' 
            ? [<p><b>Nota: La evaluación fue iniciada pero no fue terminada adecuadamente. Contacte al instructor para autorizar un nuevo periodo de evaluación del examen.</b></p>,
            <ComponenteExamenFinalizado/>]
            : null
          }

          {/* eliminar este codigo es solo para debugging */}
          {/* {estatusPeriodoExamen === 'ABIERTO' && estatusExamen === 'EN_EJECUCION' 
            ?  <ComponenteIniciarExamen/>
            : null
          } */}


          { /* El periodo de evaluacion del examen ya expiró y el estudiante nunca inicio el examen */ }
          {estatusPeriodoExamen === 'CERRADO' && estatusExamen === 'NO_INICIADO' 
            ? <p>El periodo de evaluación del examen expiró. Contacte al instructor para que autorice un nuevo periodo de evaluación.</p>
            : null
          }

          { /* El periodo de evaluacion del examen expiró, el estudiante habia iniciado el examen pero  no terminó satisfactoriamente */ }
          {estatusPeriodoExamen === 'CERRADO' && estatusExamen === 'EN_EJECUCION' 
            ? <h2>La evaluación fue iniciada pero no terminó adecuadamente, probablmente la página fue cerrada sin terminar el examen. Contacte al instructor para autorizar un nuevo periodo de evaluación.</h2>
            : null
          }

          { /* El periodo de evaluacion continua abierto pero el estudiante ya finalizo la evaluacion */ }
          {estatusPeriodoExamen === 'ABIERTO' && estatusExamen === 'FINALIZADO' 
            ? <ComponenteExamenFinalizado/>
            : null
          }

        </CardBody>
      </Card>
    ) // fin del return

  } //fin del render

} // fin PaginaInformacion

export default inject('store')(observer(PaginaInformacion))

// const titulo = {
//   marginBottom: '0px', 
// }

// const instructor = {
//   marginTop: '0px',
//   marginBottom: '0px',
//   paddingBottom: '0px', 
// }

const cardEstilo = {
  marginTop: '20px', 
}