/*
Objetivo: Componente del nombre de un curso 
Utilizacion: se usa en FormCurso

Notas
No está en uso
*/

import { Input,  } from 'mdbreact';
import React  from 'react';

import {  inject, observer,    } from "mobx-react"
import ErrorValidacion from '../../layout/ErrorValidacion' 

const ComponenteNombreUsuario = (props) => {
  const {
    icon, 
    label, 
    type, 
    value, 
    error,
    deshabilitado,
  } = props.store.formCurso.nombre.propiedades

    return (
    <div>
      <Input 
        icon = {icon}
        label = {label}
        type = {type}
        value = {value}
        disabled = {deshabilitado}

        onChange={ (e) => props.store.formCurso.nombre.asignarValor(e.target.value) }

        onBlur = {event => { 
          if (event.dispatchConfig.dependencies ==   'blur') { 
            props.store.formCurso.nombre.validar()
        }}}
      />
      <ErrorValidacion error={error}/>

    </div>
  )
}

export  default inject("store")( observer( ComponenteNombreUsuario ) )
