/* 
Objetivo:
  Mostrar la lista de cursos del estudiante.
  La lista se muestra mediante cards con imagen de cursos
  Datos a mostrar en c/card:
    Si esta abierto se muestra fechas inicio/fin de cursos
    Codigo, Nombre, estatus: cerrada, abierta, en proceso 
    En proceso es cuando el estudiante inicio la curso
    Si esta en proceso mostrar el tiempo restante en minutos/segundos para que se cierre el periodo de curso
    
*/

import  React, {Component}   from 'react';

import {
  Card, 
  Collapse,
  CollapseHeader,
  CardBody,
  CardTitle,
  CardText,
  NavLink,
  Row,
 } from 'mdbreact' 

import { inject, observer,  } from "mobx-react"

import TituloPagina from '../../layout/TituloPagina' 
import { PAGINA_CURSO } from  '../../rutas/rutas'
import ComponenteCursoCard from './ComponenteCursoCard'
import Cargando from '../../layout/Cargando'

class PaginaCursos extends Component {
  constructor(props) {
  super(props)
  }  

  render() {
    if (this.props.store.cargando) {
      return ( <Cargando/> )
    } else {
      return (
        <div className='container-fluid'>
          <Row>
            <TituloPagina titulo='Lista de cursos inscritos'/>
          </Row>
          <Row>
            {this.props.store.getCursosSnapshot().map((curso,i) => {
              return <ComponenteCursoCard key= {i} curso={curso} />
            }) }
          </Row>
        </div>
      )
    }
  }
}

export default inject('store')(observer(PaginaCursos))