import React from 'react';
import '../estilos/Estilos.css' 


const TituloPagina = (props) => 
  <div className="col-sm-12 text-center ">
    <h1 className ='titulo-pagina grey-text' > 
      <b>
        {props.titulo} 
      </b>
    </h1>
  </div>

export default TituloPagina