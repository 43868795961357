


import React, {Component}  from 'react';

import { 
  Button, 
  Card, 
  CardBody, 
  CardTitle, 
  Container,
  Col,
  Fa, 
  FormInline, 
  Row,
  Input, 
  InputFile 
} from 'mdbreact';

const Contactenos = () => {
  return(
  <p>Contactenos</p>)
}

export default Contactenos
// class Contactenos extends Component {

//   constructor (props) {
//     super(props)
//   }




//   // esta funcion la uso para capturar nombres de 
//   // attachments .. no me esta funcionando el evento
//   // onChange
//   archivos = (evento) => {
//     console.log(evento.target.files[0])
//   }

//   render(){
// 		if (this.props.store === null  || this.props.store.cargando ) {
// 			return ('Cargando')
//     }
    
//     this.props.store.form.limpiarform()

//     const ruta = [ 
//       {activo: 'active', link: '/inicio', texto: 'Inicio'},
//       {activo: 'active', link: '/contactenos', texto: 'Contáctenos'},
//     ]

//     const mensaje = this.props.store.form.mensaje

//     return(
//       <Container>
//         <TituloCentrado titulo='Envíenos un mensaje' />
//         <Row>
//           <Col md='1'/>
//           <Col md="10">
//             <Card>
//               <CardBody>
//                 <form onSubmit={this.enviarDatos}>
//                   <div className="grey-text">
//                     <NombreUsuario/>
//                     <Correo/>
//                     <NumeroTelefonoCelular/>
//                     <NumeroTelefonoEmpresa/>
//                     <Mensaje/>
//                   </div>
//                   <BotonEnviarDatos/>
//                 </form>
//               </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </Container>              
//     )
//   }
// }



// export default  inject("store")(observer(Contactenos))
// //export default RegenciaAmbientalCotizacion
 




 