/* 
Objetivo:
  Modelo de campo de nombre de usuarrio

Notas:
  No está en uso  
*/

import { types, getSnapshot } from 'mobx-state-tree'

const ModeloNombreUsuario = types
.model('ModeloNombreUsuario', {
  icon: 'user-circle',
  label: 'Nombre', 
  name: 'nombre',
  type: 'text',
  value: '',
  error: '',
  deshabilitado: true,
  errorRequerido: 'Campo no debe quedar vacío',
  errorLongitud: 'Nombre debe ser de 2 a 20 caracteres',
})

.actions (self => ({
  setValor(value) {
    self.value = value
    self.error = ''
  },

  validar() {
    if (self.value == '') {
      self.error = self.errorRequerido
      return
    }

    if (self.value.length < 2 || self.value.length > 20) {
      self.error = self.errorLongitud
      return
    }
  }

}))

.views (self => ({ 
  get getPropiedades () {
    return  getSnapshot(self)
  }

})) //fin views

export default  ModeloNombreUsuario