import { types, getSnapshot } from 'mobx-state-tree'

const ModeloCorreoUsuario = types
.model ('ModeloCorreoUsuario', {
  icon: 'envelope',
  label: 'Correo', 
  name: 'correo',
  type: 'email',
  value: '',
  error: '', 
  errorRequerido: 'Campo no puede quedar vacío',
  // errorLongitud: 'Nombre debe ser de 2 a 20 caracteres',
  errorFormato: 'Formato inválido',
})

.actions (self => ({

  setValor(value) {
     self.value = value
     self.error = ''
  },

  validar () {
    if (self.value === '') {
      self.error = self.errorRequerido
      return
    }
    if ( !( /\S+@\S+\.\S+/.test(self.value) ) ) {
      self.error = self.errorFormato
      return
    }
  }

}))

.views (self => ({ 
  getPropiedades () {
    return  getSnapshot(self)
  }

})) //fin views

export default ModeloCorreoUsuario