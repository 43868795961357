/*
  Objetivo: Componente del campo de tema en pagina de soporte
 
*/

import { Input,  } from 'mdbreact';
import React  from 'react';

import {  inject, observer,    } from "mobx-react"
import ErrorValidacion from '../../layout/ErrorValidacion' 

const ComponenteTema = (props) => {
  const {
    icon, 
    label, 
    type, 
    value, 
    error,
    deshabilitado,
  } = props.store.inputTema.getPropiedades()

    return (
    <div>
      <Input 
        icon = {icon}
        label = {label}
        type = {type}
        value = {value}
        disabled = {deshabilitado}

        onChange={ (e) => props.store.inputTema.setValor(e.target.value) }

        onBlur = {event => { 
          if (event.dispatchConfig.dependencies ==   'blur') { 
            props.store.inputTema.validar()
        }}}
      />
      <ErrorValidacion error={error}/>

    </div>
  )
}

export  default inject("store")( observer( ComponenteTema ) )
