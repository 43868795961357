/* 
Objetivo: modelo de usuario

Utilizacion:  
  Mantiene informacion de sesion de usuario.

  Lo uso en PaginaLogin para manejar campos del form de login
*/

import { types, getSnapshot, destroy } from 'mobx-state-tree'
import ModeloCorreoUsuario from './ModeloCorreoUsuario' 
import ModeloPasswordUsuario from './ModeloPasswordUsuario' 
import ModeloNombreUsuario from './ModeloNombreUsuario'
import ModeloCurso from '../curso/ModeloCurso'

// import ModeloAryRole from './ModeloAryRole' 

import authLogin from '../../firebase/config'  

const ModeloUsuario = types.model('ModeloUsuario', {

  // estos campos son para mantener datos de la sesion
  uid: '',
  roles: types.optional(types.array(types.string), []),
  correo: '',
  nombre: '',
  primerApellido: '',
  segundoApellido: '', 
  telefono: '',

  // contiene el codigo e index del curso seleccionado
  codigoCursoSeleccionado: '',   // no lo estoy usando
  cursoSeleccionadoIndex: 0, 
  preguntaSeleccionadaIndex: 0,

  cursos: types.optional(types.array(ModeloCurso),[]),
})

export default ModeloUsuario