
import React, {Component}  from 'react';
import {authLogin} from '../../firebase/config'

// import * as rutas from '../../rutas/rutas'
import { PAGINA_LISTA_CURSOS } from '../../rutas/rutas'
import { PAGINA_CONTACTENOS } from '../../rutas/rutas'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';

import { getSnapshot } from "mobx-state-tree"
import { observer, inject,  } from "mobx-react"
import { Usuario } from './ModeloUsuario'

import ComponenteCorreoUsuario from './ComponenteCorreoUsuario' 
import ComponentePasswordUsuario from './ComponentePasswordUsuario'  
import ComponenteNombreUsuario from './ComponenteNombreUsuario';
import TituloPagina from '../../layout/TituloPagina' 


import { 
  Input, 
  Button, 
  Fa, 
  Card, 
  CardText,
  CardBody,
  Row,
  Col,
} from 'mdbreact';

class PaginaLogin extends  Component {
  
  constructor(props) {
    super(props)
  }; 

  enviarCorreoResetPassword = async  ()  => {
    // console.log(this.props.store.inputCorreoUsuario.value)
    try { 
      const res = await authLogin.sendPasswordResetEmail(this.props.store.inputCorreoUsuario.value)
      toast.info('Revise su buzón de correo')
    } catch (err) {
      console.log('error en enviarCorresoResetPassword', err)
    }
    
  }

  iniciarSesion (e) {
    this.props.store.setCargando()
    authLogin.signInWithEmailAndPassword(this.props.store.inputCorreoUsuario.value, this.props.store.inputPasswordUsuario.value)
    .then((authUser) => {

      this.props.store.setUid(authUser.user.uid)
      
      this.props.store.clearFormLogin()

      this.props.history.push(PAGINA_LISTA_CURSOS) 
      // this.props.history.push(PAGINA_CONTACTENOS) 
    })
      // .catch( error => { toast.error(error.message) })
      .catch( error => { toast.error('Correo o password inválido') })
    }

  render () {

    return (
      <div className='col-10 mx-auto'>
      {/* <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4"> */}

        {/* <TituloPagina titulo='Inicio de sesión'/> */}
        <Card className='mx-auto ' style={estiloCard}>
          <CardBody>
          <div className='row justify-content-center'/>
          <p className="h5 text-center mb-4">Iniciar Sesión</p>
            <div className='col-sm-12'>
              <form>
                <ComponenteCorreoUsuario/>
                <ComponentePasswordUsuario/>

                <Row>

                  <Col>
                    <Button 
                      disabled = { (this.props.store.inputCorreoUsuario.value === '' || this.props.store.inputCorreoUsuario.error != '') || ( this.props.store.inputPasswordUsuario.value === '' || this.props.store.inputPasswordUsuario.error != '') }
                    
                      size='sm' block onClick={ e => this.iniciarSesion() } >
                      Entrar<Fa icon="sign-in" className="ml-1"/>
                    </Button>
                  </Col>

                  <Col>
                    <Button  
                      disabled = {this.props.store.inputCorreoUsuario.value === '' || this.props.store.inputCorreoUsuario.error != ''} 
                      size='sm' block 
                      onClick={ e => this.enviarCorreoResetPassword()} >
                      Olvidó su password? <Fa icon="sign-in" className="ml-1"/>
                    </Button>
                  </Col>

                </Row>

              </form>
            </div>
            <ToastContainer hideProgressBar={true}  newestOnTop={true} autoClose={5000} />
          </CardBody>
        </Card>
      </div>
    ) //fin return
  } // fin render
} // fin class

// no recuerdo para que queria usar withRouter
// export default withRouter( observer(PaginaLogin) )

// export default inject('store')(observer(PaginaLogin))

export default inject('store')(withRouter(observer(PaginaLogin)))

const estiloCard = {
  maxWidth: '50%',
  marginTop: '150px',
  // backgroundImage: "url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')"

}