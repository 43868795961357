 
/* 
  Objetivo: 

    Mostrar informacion tabla con informacion de los resultados de una curso una vez que esta se ha cerrado.
*/

import  React, {Component}   from 'react';
import { inject, observer,  } from "mobx-react"
import { Table, TableBody, TableHead  } from 'mdbreact';
import striptags from 'striptags'

const  ComponenteExamenFinalizado = (props) => {
  const s = props.store
  const selecciones = s.getReporteCurso()
  const fechaInicioClase = s.getFechaInicioClase()
  const fechafinClase = s.getFechaFinClase()
  const fechaInicioExamen = s.getFechaInicioExamen()
  const fechaFinExamen = s.getFechaFinExamen()
  const {nota, pendiente} = props.store.getNotaExamen()
  const ary = props.store.getReporteCurso().map( (s, i) => 
    <tr>
      {/* <td>{s.numero}</td> */}
      <td>{i+1}</td>

      <td> 
        {s.comentarios != ''
        ? [<p>{s.pregunta}</p>, <p><b>Comentarios del instructor</b></p>, striptags(s.comentarios) ]
        : <p>{s.pregunta}</p> } 
      </td>
      
      
      <td>{s.correctas}</td>
      <td>{s.seleccionadas}</td>
      <td>{s.valor}</td>
      <td>{s.calificacion}</td>
    </tr>
  )

  return (
    
    <div>

      <h3>Periodo y tiempo máximo de evaluación</h3>
      <p style={{margin: '0px',}}>
            {`Periodo de lecciones: ${fechaInicioClase} al ${fechafinClase} ` }
      </p>
      <p style={{margin: '0px',}}> {`Periodo de evaluacion del ${fechaInicioExamen} al ${fechaFinExamen}`} </p>
      <p style={{margin: '0px',}}>
        {`Tiempo máximo de evaluación: ${s.getTiempoMaximoExamen()} minutos` }
      </p>
      
      <hr/>

    <h3 style = {{marginTop: '30px', marginBottom: '0px'}} >Resultados del examen</h3>

    <p>{`Nota: ${nota} ${pendiente}`}</p>
    
 
      <Table>
        <TableHead>
          <tr>
            <th>#</th>
            <th>Pregunta</th>
            <th>Opciones correctas</th>
            <th>Opciones seleccionadas</th>
            <th>Valor</th>
            <th>Calificación</th>
          </tr>
        </TableHead>
        <TableBody>
          {ary}
        </TableBody>
      </Table>
    </div>
  )
} // ComponenteCursosResultados

export default inject('store')(observer(ComponenteExamenFinalizado))
