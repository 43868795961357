/* 
  Objetivo: 
    Este es el card que se usa para desplegar la lista de cursos que ha matriculado el estudiante

  Props: 
    intancia del modelo de curso
    
*/

import React, {Component}  from 'react';
import { inject, observer,  } from "mobx-react"

// Interface del usuario (GUI)
import {
  CardImage, 
  CardBody, 
  CardTitle, 
  CardText, 
  Card, 
  ListGroupItem,
  ListGroup,
  Fa,
} from 'mdbreact' 

import '../../estilos/Estilos.css'

// Routing
import { withRouter, Link } from 'react-router-dom'
import {PAGINA_CURSO} from '../../rutas/rutas'
import {PAGINA_INFORMACION} from '../../rutas/rutas'

class ComponenteCursoCard extends Component  {

  componenWillMount () {

  }
  
  render () {
    const { 
      imagenCurso,
      nombreCurso,
      codigoCurso,
      descripcionCurso,
      nombreInstructor,
      estatusExamen,
      fechaInicioClase

    } = this.props.curso
    return (
      <Card  style={CardEstilo}>

        <CardImage top src={imagenCurso}  overlay="white-slight" hover waves alt="Card image cap"/>
        
        <CardBody className="elegant-color white-text rounded-bottom">

          {/* <a className="activator waves-effect waves-light mr-4">
            <Fa icon="share-alt"></Fa>
          </a> */}

          <CardTitle>{nombreCurso}</CardTitle>

          <hr className="hr-light" />

          <CardText className="white-text">
            {nombreInstructor}
          </CardText>

          <CardText className="white-text">
            {descripcionCurso}
          </CardText>

          <hr className="hr-light" />

          {/* si uso este codigo hace un reload del app */}
          {/* <a href={`${PAGINA_INFORMACION}/${codigoCurso}`} 
                  className="black-text d-flex justify-content-end">
            <h5 className="white-text"> 
              Más información  
              <Fa icon="angle-double-right">
              </Fa>
            </h5>
          </a> */}


          <Link className="black-text d-flex justify-content-end" style={cssLinkInformacion} to={`${PAGINA_INFORMACION}/${codigoCurso}`}>

            <h5 className="white-text"> 
                Más información <Fa icon="angle-double-right"/>
            </h5>

          </Link>   
        
        </CardBody>
      </Card>


    ) //fin return
  }
} //fin class

// export default ServicioItem
export default withRouter(ComponenteCursoCard)

// export default  withRouter(inject('store')(observer(ComponenteCursoCard)))

const CardEstilo = {
  maxWidth: '25%',
  margin: '2%',
}


const cssLinkInformacion = {
  padding: '20px',
}



// {/* <Card key={codigoCurso} style={cssCard}>
// <CardImage  style={cssImage} src={imagenCurso}/>
// <CardBody style={cssBody}>
//   <CardTitle  style={ccsCardTitle}>{nombreCurso}</CardTitle>
//   <ListGroup style={cssListGroup} >
//     <ListGroupItem>Por: {nombreInstructor}</ListGroupItem>
//     <ListGroupItem>
//       {descripcionCurso} 
//     </ListGroupItem>
//     <ListGroupItem style={cssLinkCursos}>
//     <Link style={cssLinkInformacion} to={`${PAGINA_INFORMACION}/${codigoCurso}`}> Más información</Link>   

//     {/* {estatusExamen === 'No iniciado'
//       ? <Link style={cssLinkCursos} to={`${PAGINA_CURSO}/${codigoCurso}`}>Iniciar curso</Link>
//       : ''}  */}

// //     </ListGroupItem>
// //   </ListGroup>
// // </CardBody> 
// // </Card> */}