
/* 
Objetivo:
  Panel lateral derecho de preguntas.

Props:
  Recibe objeto tipo 'curso' con toda la informacion de la curso. Es pasada por PaginaCurso.
  
Funcionalidad:
  Despliega panel de preguntas y selecciones.
*/

import React, {Component}  from 'react';

// Interface del usuario (GUI)
import {
  CardImage, 
  CardBody, 
  CardTitle, 
  CardText, 
  Card, 
  ListGroupItem,
  ListGroup,
} from 'mdbreact' 

import '../../estilos/Estilos.css'
import { withRouter, Link } from 'react-router-dom'
import { inject, observer,  } from "mobx-react"
import ComponenteTipoPregunta from './ComponenteTipoPregunta' 

class ComponentePreguntasPanel2 extends Component  {
  constructor(props)  {
    super(props)
  }
  
  render () {
    const {
      enunciado,
      imagen, 
      numero,
      porcentaje,
      pregunta,
      tipo,
    } = this.props.store.getPreguntaSeleccionadaSnapshot()

    return (
      <Card>
        {/* <p style={cssTitulo} >Pregunta número {numero}</p> */}
        
        <p style={cssTitulo} > 
        {  `Pregunta número ${this.props.store.getPreguntaSeleccionadaIndex() + 1} (${this.props.store.getTipoDePregunta()})` }
        </p>
        
        {/* <hr/> */}
        
        {/* <p style={cssSubtitulo}>Enunciado</p>
        <p style={cssTexto} >
          {enunciado}
        </p> */}
        

        <hr/>

        { imagen 
          ? [<p style={cssSubtitulo}>Imagen</p>,

            <CardImage className="img-fluid" style={cssImagen} src={imagen} />,

            <a  target="_blank" href={imagen} className="ml-2" download='imagenCurso.png'>
                <i style={{marginLeft:'140px', marginTop:'10px'}} className="fa fa-download">Abrir imagen</i></a>,

            <hr/>]
          : null
        }  

        <p style={cssSubtitulo}>Pregunta</p>
        <p style={cssTexto} >
          {pregunta}
        </p>

        <hr/>


        {tipo === 'desarrollo'
          ? <p style={cssSubtitulo}>Respuesta</p>
          : <p style={cssSubtitulo}>Selecciones</p>
        }

        <div style= {{marginLeft: '40px', marginBotton:'20px'}}>
          <ComponenteTipoPregunta/>
        </div>

      

      </Card>
    ) //fin return
  }
} //fin class

export default inject('store')(observer(ComponentePreguntasPanel2))

const cssTitulo = {
  margin: '40px',
  padding: '0px',
  color: 'black',
  fontSize: '25px',
  fontStyle: 'bold',
  textAlign: 'center',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const cssSubtitulo = {
  marginLeft: '20px',
  marginTop: '10px', 
  marginBotton: '5px',
  color: 'black',
  fontSize: '25px',
  // fontStyle: 'bold',
  // textAlign: 'center',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const cssTexto = {
  marginLeft: '50px',
  marginRight: '20px', 
  marginTop: '5px', 
  marginBotton: '50px',
  color: 'black',
  fontSize: '25px',
  // fontStyle: 'bold',
  // textAlign: 'center',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const cssImagen = {
  maxWidth: '400px',
  maxHeight: '300px',
  marginLeft: '30px',
  marginRight: '20px', 
  marginTop: '5px', 
  marginBotton: '150px',
}

