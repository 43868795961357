import React, {Component}  from 'react';
import { inject, observer,  } from "mobx-react";

import ComponenteTema from './ComponenteTema'
import ComponenteMensaje from './ComponenteMensaje'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Card,
  CardBody,
  Input,
  Fa,
  Button,
  Row, 
  Col,
} from 'mdbreact' 

const enviarMensaje = (props) => {
  props.store.saveMensajeSoporte()
  toast.info('Mensaje enviado a soporte, pronto le contestaremos...')
}

const PaginaSoporte = (props) => {
  return (
    <div style={estiloDiv}  className='col-6 mx-auto'>
      <Card>
        <CardBody>
          <div className="form-header default-color">
            <h3 className="mt-2"><Fa icon="envelope" /> Escríbanos</h3>
          </div>
          
          <ComponenteTema/>
          <ComponenteMensaje/>

          {/* <div className="md-form">
            <Input icon="tag" label="Tema" iconClass="grey-text" type="text" id="form-subject" />
          </div>
          <div className="md-form">
            <Input icon="pencil" label="Su mensaje..." iconClass="grey-text" type="textarea" id="form-text" />
          </div> */}


          <Row className="text-center">
            <Col>
              <Button btn small  onClick = { e => enviarMensaje(props) } >
                Enviar mensaje
              </Button>
            </Col>
            <Col>
              <Button btn small  onClick={ e => props.store.clearMensajeForm() }
              >Limpiar</Button>
            </Col>
          </Row>

          <ToastContainer hideProgressBar={true}  newestOnTop={true} autoClose={5000} />

        </CardBody>
      </Card>
    </div>
  )
}

 export default inject('store')(observer(PaginaSoporte))


const estiloDiv = {
  marginTop: '200px',
  marginBottom: '50px'
}