

import  React, {Component}   from 'react';
import { inject, observer,  } from "mobx-react";
import TituloPagina from '../../layout/TituloPagina';
import {PAGINA_CURSO} from '../../rutas/rutas';
import {storage} from '../../firebase/config'
import {withRouter} from 'react-router'

import {
  Button,
  Modal, 
  ModalBody, 
  ModalHeader, 
  ModalFooter 
} from 'mdbreact'

class ComponenteIniciarExamen extends Component  {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }
  
  render ()  {
    const s = this.props.store
    const fechaInicioClase = s.getFechaInicioClase()
    const fechafinClase = s.getFechaFinClase()
    const fechaInicioExamen = s.getFechaInicioExamen()
    const fechaFinExamen = s.getFechaFinExamen()
    const examenEstatus = s.getEstatusExamen()
    const periodoExamenEstatus = s.getEstatusPeriodoExamen()
    const imagenCurso = s.getImagenCurso()
    const instrucciones = s.getInstrucciones()
    const descripcion = s.getDescripcion()

    return(
      <div>
        

        <Button onClick={this.toggle}>Iniciar examen</Button>

        <hr/>

        <h3>Periodo y tiempo máximo de evaluación</h3>
        <p style={{margin: '0px',}}>
              {`Periodo de lecciones: ${fechaInicioClase} al ${fechafinClase} ` }
        </p>
        <p style={{margin: '0px',}}> {`Periodo de evaluacion del ${fechaInicioExamen} al ${fechaFinExamen}`} </p>
        <p style={{margin: '0px',}}>
          {`Tiempo máximo de evaluación: ${s.getTiempoMaximoExamen()} minutos` }
        </p>
        
  
        <hr/>
  
        <h3>Descripción</h3>
        {s.getDescripcion().map((ins, i) => {
          return (<p key={i}>{ins}</p>)
        })}
  
        <hr/>
  
        <h3>Instrucciones</h3>
        {s.getInstrucciones().map((ins, i) => {
          return (<p key={i}>{ins}</p>)
        })}
          
        {/* Por el momento no incluyo materiales del curso */}
        {/* {imagenCurso != ''
          ? [<h2 style = {{marginTop: '30px',}} >Materiales</h2>,
            <a target="_blank" href={imagenCurso} className="ml-2" download='imagenCurso.png'>,
            <i className="fa fa-download"></i></a>, ]
  
          : null   }      */}
  
        {/* <Button onClick={() => this.props.history.push(`${PAGINA_CURSO}/${this.props.match.params.codigoCurso}`)}>
          Iniciar Examen
        </Button>  */}

        
        
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Iniciar examen</ModalHeader>
          <ModalBody>
            <p>Una vez iniciado el examen, se debe completar en el periodo indicado. </p>
            <p>El examen no puede ser detenido para reiniciarse posteriormente.</p>
            <p>No cierre la página o el navegador hasta que haya concluido el examen.</p>

            Seguro que desea iniciar el examen ya?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancelar</Button>{' '}
            <Button color="default" onClick={() => this.props.history.push(`${PAGINA_CURSO}/${this.props.match.params.codigoCurso}`)}>Iniciar examen</Button>
          </ModalFooter>
        </Modal>
  
  
      </div>
    )
  }
  
} //fin ComponenteCursoAbierto


export default inject('store')(withRouter(observer(ComponenteIniciarExamen)))

const marginTop = {
  marginTop: '20px', 
  
}