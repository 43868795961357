

// React
import React from 'react';
import { observer, inject,  } from "mobx-react"
import '../estilos/Estilos.css' 


// Interface del usuario (GUI)
import { Col, Container, Row, Footer } from 'mdbreact';

const PiePagina = (props) => {
  let nombre = props.store.usuario.uid === ''
  ?  'Sesión inactiva'
  :  props.store.getNombreCompleto()

  props.store.ambiente === 'STAGING'
  ? nombre = `${nombre} (STAGING)`
  : nombre = nombre

  return(
    <Footer className= 'estilo-footer' color={ color }>
      <Row>
        <Col >
          <h5 className="estilo-texto-fotter">{nombre}</h5>
        </Col>

        <Col style={textoCentro}>
            <h5 className="estilo-texto-fotter">
              Transformaciones Químicas S.A.
            </h5>
        </Col>

        <Col style={textoDerecha}>
            <h5 className="estilo-texto-fotter">
              Sistema de evaluación de Cursos
            </h5>
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign: 'center'}}>
          <p>
            &copy; {(new Date().getFullYear())} Todos los derechos reservados/>
          </p>
        </Col>
      </Row>


  <div className="footer-copyright text-center">
  </div> 

    </Footer>
    );
}
      
export default inject('store')(observer(PiePagina))

 const textoDerecha = {
   textAlign: 'right',
 }

 const textoCentro = {
    textAlign: 'center',
 }

const color = 'elegant-color-dark'
// const color = 'light-green darken-3'

const estiloFooter =  {
  marginBottom:'0px'
}