// Firebase config

// import firebase from 'firebase'
import firebase from 'firebase'
import 'firebase/firestore' 

import { store } from '../store/iniciarApp';

const stagingConfig  = {
  apiKey: "AIzaSyCj_SHZbx2rXU2WoMJIVxCwypN4k_WPSfc",
  authDomain: "cursos-staging.firebaseapp.com",
  databaseURL: "https://cursos-staging.firebaseio.com",
  projectId: "cursos-staging",
  storageBucket: "",
  messagingSenderId: "607021061676"
};

const productionConfig = {
  apiKey: "AIzaSyC_kNKsP-HRQGZbuA4soCFEmxiVE09XyZg",
  authDomain: "traquisa-cursos.firebaseapp.com",
  databaseURL: "https://traquisa-cursos.firebaseio.com",
  projectId: "traquisa-cursos",
  storageBucket: "traquisa-cursos.appspot.com",
  messagingSenderId: "719020273824" 
};

let firebaseConfig = null
let ambiente = window.ambiente = process.env.REACT_APP_AMBIENTE


const setFirebaseConfig = () => {
  
  switch (ambiente) {

    case 'STAGING':
      firebaseConfig = stagingConfig
      break;
  
    case 'PRODUCTION':
      firebaseConfig = productionConfig
      break;
  
    default: 
      console.log ('error en configuracion de firebase')
    }
  }

// firebase.initializeApp(firebaseConfig);

const settings = {
  timestampsInSnapshots: true
}

let database = null
let authLogin = null
let storage = null 


const  inicializarFirebase = () => {
  setFirebaseConfig()
  firebase.initializeApp(firebaseConfig)
  database = firebase.firestore()
  authLogin = firebase.auth()
  database.settings(settings)
  storage = firebase.storage();
} // fin inicialiarFirebase
 

export { database, authLogin, inicializarFirebase, storage }