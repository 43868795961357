import React, { Component }  from 'react';
import { inject, observer,  } from "mobx-react"

import {
  Container, 
  Button,
  Col,
  Row,
  MDBDatePicker,
  View,
  Card,
  CardTitle,
  CardText,
  Fa,
} from 'mdbreact' 

import { database, authLogin } from '../firebase/config'  
import { PAGINA_LISTA_CURSOS} from '../rutas/rutas';
import TituloPagina from '../layout/TituloPagina'

const PaginaInicio = (props) => {
  return(
    <Card style={cardEstilo} className="card-image">
      <div className="text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded">
        <div>

          <h4 className="py-3 font-weight-bold">
            <strong>Sistema de examenes de cursos de TRAQUISA</strong>
          </h4>

          <Row>
            <Col className='col-sm-2'>
            </Col>
            <Col className='col-sm-8'>
              <p className="pb-3">Transformaciones Químicas S.A. es una empresa que ofrece amplios servicios en el área de Ingenería Química en Costa Rica.</p>
              <p className="pb-3">Uno de los servicios más importantes de Traquisa es la transferencia de concocimiento a colegas a través de seminarios y cursos en áreas como Hidrocarburos, Regencias Químicas, Análisis de riesgos, etc.</p>
              <p className="pb-3">              En este sitio los usuarios registrados podrán tomar las evaluaciones de los cursos que hayan matriculado con Traquisa y patrocinados por el Colegio de Ingenieros Químicos y Profesionales afines.</p>
            </Col>
            <Col className='col-sm-2'>
            </Col>
          </Row>

          {/* <Button color="secondary" rounded size="md"><Fa icon="clone" className="left"/> View project</Button> */}

          {props.store.usuario.uid != '' &&
            <Button onClick = { () => props.history.push(PAGINA_LISTA_CURSOS) }>
              <Fa icon="clone left" />
              Mis cursos...
            </Button>}
        </div>
      </div>
    </Card>
  )
}

export default inject('store')(observer(PaginaInicio))

const cardEstilo = {
  marginTop: '24px',
  // paddingBotton: '0px',
  // height: '700px',
  backgroundImage: "url('https://mdbootstrap.com/img/Photos/Horizontal/Work/4-col/img%20%2814%29.jpg')"
}