import React, {Component}  from 'react';
import {  inject, observer,    } from "mobx-react"
import { getSnapshot } from 'mobx-state-tree'
// import { Link } from 'react-router-dom';
import { authLogin } from '../firebase/config'
import '../estilos/Estilos.css'
import * as rutas from '../rutas/rutas'
import {withRouter} from 'react-router'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	Button,
	Fa,
	Navbar, 
	NavbarBrand, 
	NavItem, 
	NavLink, 
	NavbarNav, 
	Collapse,	
	NavbarToggler,
	DropdownToggle, 
	Dropdown, 
	DropdownMenu, 
	DropdownItem,

} from 'mdbreact';

class BarraNav extends Component {

	constructor  (props)  {
		super(props)
		this.state = {
			collapse: false,
			isWideEnough: false,
		}
	}

	navbarToglerHandler = () => {
		this.setState({	collapse: !this.state.collapse	})
	} 

	terminarSesion = () => {
		authLogin.signOut()
		this.props.history.push(rutas.PAGINA_LOGIN)
	}

	iniciarSesion = () => {
		this.props.history.push(rutas.PAGINA_LOGIN)
	}
	
	render () {
		
		// if (this.props.store === null  || this.props.store.cargando ) {
    //   return ( <div>Cargando Lista de cursos</div>  )
		// }
		
		return (
			<div>
				<Navbar fixed="top" color={ color } dark expand="lg" scrolling className='barra-nav' style={barraNav}>
	
					<NavbarBrand href={rutas.PAGINA_INICIO}> 
						<img src= "LogoTraquisa-200x141.png" height="75" alt=''/><span className='tit-ciqpa' >Transformaciones Químicas S.A.</span>
					</NavbarBrand>
	
					{ !this.state.isWideEnough && <NavbarToggler onClick = { this.navbarToglerHandler } />}
					<Collapse isOpen = { this.state.collapse } navbar>
								
							<NavbarNav left>
	
								{this.props.store.usuario.uid != '' &&
								<NavItem>
									<NavLink  to={rutas.PAGINA_LISTA_CURSOS}>Cursos</NavLink>
								</NavItem> }
	
								{this.props.store.usuario.uid != '' &&
								<NavItem>
									<NavLink  to={rutas.PAGINA_SOPORTE}>Soporte</NavLink>
								</NavItem>}
	
							</NavbarNav>
							
							<NavbarNav right> 
								
								{/* sesion inactiva */}
								{this.props.store.usuario.uid === '' &&
										(<NavItem>
											<Button size='sm' onClick={ e => this.iniciarSesion() } >Iniciar sesión<Fa icon="sign-in" className="ml-1"/></Button>
										</NavItem>)}
	
								{/* sesion activa */}
								{this.props.store.usuario.uid != '' && 
										(<NavItem>
											<Button size='sm' onClick={ e => this.terminarSesion() } >
												Finalizar sesión <Fa icon="sign-out" className="ml-1"/>
											</Button>
										</NavItem>)} 
								</NavbarNav>
	
					</Collapse>
				</Navbar>
				
				<ToastContainer hideProgressBar={true}  newestOnTop={true} autoClose={5000} />
			</div>
		)
	}
}

export default inject('store')(withRouter(observer(BarraNav)))

const barraNav = {
	margin: '15px',
	marginTop: '0px'
}

const color = 'elegant-color-dark'
// const color = 'light-green darken-3'