/* 
Mantener esta función separada de Store.js.
Ambos archivos (iniciarApp y Store.js van a crecer mucho )
*/

import { database, authLogin } from '../firebase/config'  
import ModeloStore from './ModeloStore' 
import ModeloCurso from '../entidades/curso/ModeloCurso'

import ModeloUsuario from '../entidades/usuario/ModeloUsuario';
import ModeloCorreoUsuario from '../entidades/usuario/ModeloCorreoUsuario'; 
import ModeloPasswordUsuario from '../entidades/usuario/ModeloPasswordUsuario'; 

import ModeloTema from '../entidades/soporte/ModeloTema'
import ModeloMensaje from '../entidades/soporte/ModeloMensaje'

import { applySnapshot } from 'mobx-state-tree'

let store = null

/*
  Esta funcion la estoy usando para modelar la coleccion 'cursos' en firebase.
  usuarioId es igual al userid del user de authentication in fb
*/

/* El store se crea inicialmente con los modelos de usuario para poder manejar el form de login. El modelo de Arycursos se puede crear y cargar hasta que el usuario haya realizado el login. */


const iniciarApp = () => {
  store = window.store = ModeloStore.create({
    usuario: ModeloUsuario.create(),
    
    // modelos para manejar campos de form de login
    inputCorreoUsuario: ModeloCorreoUsuario.create(),
    inputPasswordUsuario: ModeloPasswordUsuario.create(),   

    // modelos para manejar campos de form de solicitud de soporte
    inputTema: ModeloTema.create(),
    inputMensaje: ModeloMensaje.create(),
  })
  
  async function inicializarSesion (authUser) {
    store.setCargando()
    
    // const usuario = await database.collection('usuarios').doc(authUser.uid).get()
    const snapshot = await database.collection('usuarios').doc(authUser.uid)
    
    const usuario = await snapshot.get()
    
    store.setDataSesion(usuario.data())
    store.setUid(authUser.uid)
    store.setAmbiente(process.env.REACT_APP_AMBIENTE)

    // Recorre cursos del estudiante. Marca estatusPeriodExamen dependiendo de las fechas.

    store.usuario.cursos.forEach( (c, index) => {
      const fechaHoraActual = (new Date()).getTime()
      if (fechaHoraActual < c.fechaInicioExamen) store.setEstatusPeriodoExamen(index,'NO_INICIADO')
      if (fechaHoraActual > c.fechaFinExamen) store.setEstatusPeriodoExamen(index,'CERRADO')
      if (fechaHoraActual > c.fechaInicioExamen && fechaHoraActual < c.fechaFinExamen ) store.setEstatusPeriodoExamen(index, 'ABIERTO')
    })

    // Salva en FB la actualizacion hecha a esttatusPeriodoExamen
    store.saveCursosInFirebase()
  
    store.unsetCargando()
  } //fin inicializarSesion

  authLogin.onAuthStateChanged(authUser => {
    if (authUser) {
      inicializarSesion(authUser)
    } else {
      store.clearDataSesion()
    }
  })

}  //fin iniciarApp

export { store, iniciarApp }



/*
$(document).ready(function () {
  $('#txtDate').datepicker();
  $('#follow_Date').datepicker();
});

function getdate() {
  var tt = document.getElementById('txtDate').value;

  var date = new Date(tt);
  var newdate = new Date(date);

  newdate.setDate(newdate.getDate() + 3);
  
  var dd = newdate.getDate();
  var mm = newdate.getMonth() + 1;
  var y = newdate.getFullYear();

  var someFormattedDate = mm + '/' + dd + '/' + y;
  document.getElementById('follow_Date').value = someFormattedDate;
}


  var today       =new Date();
  var in_a_week   =new Date().setDate(today.getDate()+7);
  var ten_days_ago=new Date().setDate(today.getDate()-10);


*/