import React, {Component}  from 'react';
import { inject, observer,  } from "mobx-react";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

import {
  Card,
  CardBody,
  CardTitle,
  Input,
  Fa,
  Button,
  Row, 
  Col,
} from 'mdbreact' 


class PaginaDraft extends Component {

  constructor(props) {
    super(props)
    this.state = { text: '' } // You can also pass a Quill Delta here
    // this.handleChange = this.handleChange.bind(this)
  }

  handleChange= (value) => {
    this.setState({ text: value })
  }

  render () {
    return (
      <div style={estiloDiv}  className='col-6 mx-auto'>
        <Card>
          <CardBody>
            <CardTitle tag="h3" className="pt-2"><strong>Página Draft</strong></CardTitle>

            
             <ReactQuill value={this.state.text}
                  onChange={this.handleChange} />
            
            <ToastContainer hideProgressBar={true}  newestOnTop={true} autoClose={5000} />
  
          </CardBody>
        </Card>
      </div>
    )
  } // fin render 

} // fin PaginaDraft

//  export default inject('store')(observer(PaginaDraft))
export default PaginaDraft

const estiloDiv = {
  marginTop: '200px',
  marginBottom: '50px'
}