/*  
Objetivo: Definicion de modelo de Seleccion y ArySeleccion
*/ 

import { types, getSnapshot } from 'mobx-state-tree'

const ModeloSeleccion = types
.model('ModeloSeleccion', { 
  numero: 0,
  texto: '',
  imagen: '',
  correcta: false,
  seleccionada: false,
  respuesta: ''
})

export default ModeloSeleccion