// mobx-state-tree
import {types, getSnapshot, applySnapshot, destroy } from 'mobx-state-tree'
import {database} from '../firebase/config' 

import ModeloCurso from '../entidades/curso/ModeloCurso'
import ModeloUsuario from '../entidades/usuario/ModeloUsuario' 
import ModeloCorreoUsuario from '../entidades/usuario/ModeloCorreoUsuario'  
import ModeloPasswordUsuario from '../entidades/usuario/ModeloPasswordUsuario'

import ModeloTema from '../entidades/soporte/ModeloTema'
import ModeloMensaje from '../entidades/soporte/ModeloMensaje'

import formatearFecha from '../utilitarios/formatearFecha'

// Estoy utilizando modelo v1 (ver doc src/documentacion/TraquisaCursosModelosEstudiante.xlsx)
const ModeloStore = types
.model('Store', {
  cargando: true,
  usuario: types.maybe(ModeloUsuario),

  // estos dos campos son para el correo y password de la pagina de login
  inputCorreoUsuario: types.maybe(ModeloCorreoUsuario),
  inputPasswordUsuario: types.maybe(ModeloPasswordUsuario),

  //estos campos son para el form de la pagina de soporte
  inputTema: types.maybe(ModeloTema),
  inputMensaje: types.maybe(ModeloMensaje), 

  // este campo es para verificar contra que ambiente esta corriendo el app
  ambiente: ''
})

.actions(self => ({
  
  clearDataSesion() {
    self.usuario.uid = ''
    self.usuario.correoSesion = ''
    self.usuario.nombreSesion = ''
    self.usuario.telefonoSesion = ''
    self.usuario.roles = [] 
    self.usuario.cursos = []
  },
  
  clearFormLogin () {
    self.inputCorreoUsuario.value = ''
    self.inputPasswordUsuario.value = '' 
  },

  clearMensajeForm () {
    self.inputMensaje.value = ''
    self.inputTema.value = ''
  },

  decrementTiempoRemanente () {
    const e =  self.usuario.cursoSeleccionadoIndex
    self.usuario.cursos[e].tiempoRemanenteExamen = self.usuario.cursos[e].tiempoRemanenteExamen - 1
  },

  // Salva en FB el array cursos (todos los cursos) del usuario
  // No tengo un action que salve un curso en particular 
  saveCursosInFirebase() {
    const cursos = getSnapshot(self.usuario.cursos)
    const usuarioRef = database.collection('usuarios').doc(self.usuario.uid)
    usuarioRef.update({cursos: cursos})
    .then (console.log('cursos actualizados'))
    .catch (error => console.log('error en grabación de cursos', error))

    // no me funciono actualizacion de arrays.
    // usuarioRef.update({
    //   'cursos': FieldValue.arrayRemove(CursosVieja),
    //   'cursos': FieldValue.arrayUnion(CursosNueva)
    // })
    // .then (console.log('seleccion actualizada'))
    // .catch (error => console.log('error en grabación de seleccion', error))
  },

  saveMensajeSoporte () {
    const mensajeRef = database.collection('mensajes').doc(self.usuario.uid)
    mensajeRef.set({
      mensaje: self.inputMensaje.value,
      correo: self.usuario.correo,
      tema: self.inputTema.value,
      nombre: self.getNombreCompleto(),
    })
      .then (console.log('mensaje grabado en FB'))
      .catch(error => console.log('error en grabacion de mensaje a soporte'))
  },

  setAmbiente(ambiente) {
    self.ambiente = ambiente
  },

  setCargando () {
    self.cargando = true
  },

  // Califica las preguntas comparando las opciones seleccionadas y las selecciones correctas. 
  setCalificaciones() {
    const curso = self.getCursoSnapshot()
    const preguntas = curso.preguntas
    let selecciones = []

    // asume que la pregunta obtuvo una respuesta buena
    let respuestaCorrecta = true
    for (let i=0; i < preguntas.length; i++) {

      selecciones = preguntas[i].selecciones
      respuestaCorrecta = true

      // No se califican las preguntas tipo desarrollo.
      if (preguntas[i].tipo === 'multiple' ||  preguntas[i].tipo === 'unica') {
        for (let j = 0; j < selecciones.length ; j++) {
          respuestaCorrecta = respuestaCorrecta && selecciones[j].correcta === selecciones[j].seleccionada  
        }

        self.usuario.cursos[self.usuario.cursoSeleccionadoIndex].preguntas[i].porcentajeCalificacion = 
        respuestaCorrecta ? self.usuario.cursos[self.usuario.cursoSeleccionadoIndex].preguntas[i].porcentajeValor : 0 

      }
    }
  },

  // Guarda en modelo usuario el index del curso seleccionado por el usuario en PaginaLista
  setCursoSeleccionadoIndex (cursoIndex) {
    self.usuario.cursoSeleccionadoIndex = cursoIndex
  },

  setDataSesion (data)  {
    applySnapshot(self.usuario, data)

    // self.usuario
    // self.usuario.correoSesion = data.correo,
    // self.usuario.nombreSesion = data.nombre,
    // self.usuario.telefonoSesion = data.telefono,
    // self.usuario.roles = data.roles
    // self.correo.asignarValor('')
    // self.password.asignarValor('')
  },

  // puede ser 'NO_INICIADO', 'EN_EJECUCION','FINALIZADO'
  setEstatusExamen(estatusExamen) {
    const e =  self.usuario.cursoSeleccionadoIndex
    self.usuario.cursos[e].estatusExamen = estatusExamen
  },

  // cambia el estado de estatusPerioddoExamen. Con esta variable controlo si el periodo del examen esta NO_INICIADO, ABIERTO o CERRADO
  setEstatusPeriodoExamen(indexCurso, estatusPeriodoExamen) {
    self.usuario.cursos[indexCurso].estatusPeriodoExamen = estatusPeriodoExamen
  },

  /* Maneja el estado de pregunta seleccionada en el selector de preguntas en PaginaCurso */
  setPreguntaSeleccionada (preguntaIndex) {
    self.usuario.preguntaSeleccionadaIndex = preguntaIndex
  },

  setRespuesta(seleccion, texto) {
    const e = self.usuario.cursoSeleccionadoIndex 
    const p = self.usuario.preguntaSeleccionadaIndex
    self.usuario.cursos[e].preguntas[p].selecciones[seleccion].respuesta = texto

    self.usuario.cursos[e].preguntas[p].selecciones[seleccion].seleccionada = ( texto != '')
  },

  // Selecciona una respuesta (o seleccion) de una pregunta
  setSeleccionUnica(seleccionIndex) {
    const c = self.getCantidadSelecciones()
    const e = self.usuario.cursoSeleccionadoIndex
    const p = self.usuario.preguntaSeleccionadaIndex
    
    for (let i=0; i < c; i++) {
      if (i === seleccionIndex){
        const v = self.usuario.cursos[e].preguntas[p].selecciones[seleccionIndex].seleccionada 
        self.usuario.cursos[e].preguntas[p].selecciones[seleccionIndex].seleccionada = !v
      } else {
        self.usuario.cursos[e].preguntas[p].selecciones[i].seleccionada = false
      }
    }
  },

  setSeleccionMultiple(seleccionIndex) { 
    const e = self.usuario.cursoSeleccionadoIndex
    const p = self.usuario.preguntaSeleccionadaIndex
    const v = self.usuario.cursos[e].preguntas[p].selecciones[seleccionIndex].seleccionada
    self.usuario.cursos[e].preguntas[p].selecciones[seleccionIndex].seleccionada = !v

    const cursos = getSnapshot(self.usuario.cursos)
    const usuarioRef = database.collection('usuarios').doc(self.usuario.uid)
    usuarioRef.update({cursos: cursos})
    .then (console.log('cursos actualizada'))
    .catch (error => console.log('error en grabación de cursos', error))
  },

  setUid (uid) {
    self.usuario.uid = uid
  },

  unsetCargando ()  {
    self.cargando = false
  },

}))

.views( self => ({
  
  getAmbiente() {
    return self.ambiente
  },

  // retorna la cantidad de preguntas que tiene la curso actualmente seleccionada
  getCantidadPreguntas() {
    const curso = self.usuario.cursos[self.usuario.cursoSeleccionadoIndex]
    const preguntas = curso.preguntas[self.usuario.preguntaSeleccionadaIndex]
    return preguntas.length
  },

  // retorna la cantidad de selecciones que tiene la pregunta actualmente seleccionada
  getCantidadSelecciones() {
    const curso = self.getCursoSnapshot()
    const pregunta = curso.preguntas[self.usuario.preguntaSeleccionadaIndex]
    const selecciones = pregunta.selecciones
    return selecciones.length
  },

  // retorna la cantidad de selecciones de la pregunta index
  // No esta en uso
  getCantidadSeleccionesdePregunta (index) {
    const curso = self.usuario.cursos[self.usuario.cursoSeleccionadoIndex]
    const pregunta = curso.preguntas[index]
    const selecciones = pregunta.selecciones 
    return selecciones.length
  },

  // Retorna el codigo de curso actualmente seleccionad
  getCodigoCurso() { 
    const curso = self.getCursoSnapshot()
    return curso.codigoCurso
  },

  getCorreoIntructor () {
    const curso = self.getCursoSnapshot()
    return curso.correoInstructor
  },

  // retorna si el curso todavia se encuentra en el periodo valido para realizar el examen
  getCursoAbierto () {
    // const curso = self.usuario.cursos[self.usuario.cursoSeleccionadoIndex]
    const curso = self.getCursoSnapshot()
    const fechaHoraActual = (new Date()).getTime()

    return fechaHoraActual < curso.fechaFinExamen && fechaHoraActual > curso.fechaInicioExamen
  },

  //retorna el index de una curso en el array de curso basado en codigo de curso
  getCursoIndex(codigoCurso) {
    return self.usuario.cursos.findIndex(e => e.codigoCurso === codigoCurso)
  },

  // retorna snapshot del array de cursos. 
  getCursosSnapshot () {
    return getSnapshot(self.usuario.cursos)
  },
  
  /* 
    Retorna snapshot de un curso con base en codigo de curso.
    Si el codigoCurso viene en nulo entonces retorna snapshot correspondiente
    al curso seleccionado.
   */
  getCursoSnapshot(codigoCurso) {
    let curso = null
    if (codigoCurso) {
      const cursos = getSnapshot(self.usuario.cursos)
      curso =  cursos.find(e => e.codigoCurso == codigoCurso)
    } else {
      const e = self.usuario.cursoSeleccionadoIndex
      curso = getSnapshot(self.usuario.cursos[e])
    }
    return curso
  },
   
  // /*
  //   Retorna estatus del examen del curso actualmente seleccionado
  //   Opciones: 'No iniciado', 'En ejecución', 'Finalizado' 
  // */
  // getEstatusExamen() {
  //   const e = self.usuario.cursoSeleccionadoIndex
  //   return self.usuario.cursos[e].estatusExamen
  // },

  getDescripcion () {
    const curso = self.getCursoSnapshot()
    return curso.descripcionCurso
  },

  // Retorna el estatus del examen (NO_INICIADO, EN_EJECUCION, FINALIZADO)
  getEstatusExamen () {
    // const curso = self.usuario.cursos[self.usuario.cursoSeleccionadoIndex]
    const curso = self.getCursoSnapshot()
    return curso.estatusExamen
  },

  // Retorna el estatus del periodo del examen: (NO_INICIADO, ABIERTO, CERRADO)
  getEstatusPeriodoExamen() {
    const curso = self.getCursoSnapshot()
    return curso.estatusPeriodoExamen
  },

  // Retorna falso o verdadero si la seleccion ha sido marcada (escogida)
  // Lo uso en ComponenteTipoPregunta
  getEstatusSeleccion(seleccionIndex) {
    const curso = self.usuario.cursos[self.usuario.cursoSeleccionadoIndex]
    const pregunta = curso.preguntas[self.usuario.preguntaSeleccionadaIndex]
    const selecciones = getSnapshot(pregunta.selecciones)
    return selecciones[seleccionIndex].seleccionada
  },

  //retorna un objeto con todas las fechas del curso seleccionado. Lo uso principalmente para debugging
  getFechasCurso() {
    const curso = self.getCursoSnapshot()
    return({
      fechaInicioClase: self.getFechaInicioClase(),
      fechafinClase: self.getFechaFinClase(),
      fechaInicioExamen: self.getFechaInicioExamen(),
      fechaFinExamen: self.getFechaFinExamen(),
    })
  },
  
  getFechaFinExamen () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechaFinExamen)
    return formatearFecha(fecha)
  },

  getFechaFinClase () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechafinClase)
    return formatearFecha(fecha)
  },

  getFechaInicioClase () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechaInicioClase)
    return formatearFecha(fecha)
  },

  getFechaInicioExamen () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechaInicioExamen)
    return formatearFecha(fecha)
  },

  getFechaFinClase () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechaFinClase)
    return formatearFecha(fecha)
  },

  getFechaInicioClase () {
    const curso = self.getCursoSnapshot()
    const fecha = new Date(curso.fechaInicioClase)
    return formatearFecha(fecha)
  },

  getImagenCurso () {
    const curso = self.getCursoSnapshot()
    return curso.imagenCurso
  },

  getInstrucciones () {
    const curso = self.getCursoSnapshot()
    return curso.instrucciones
  },

  getMinutosRemanenteExamen () {
    const curso = self.getCursoSnapshot()
    return curso.tiempoRemanenteExamen
  },

  // concatena nombres y apellidos. Se usa en pie de pagina
  getNombreCompleto() {
    return (
      `${self.usuario.nombre} ${self.usuario.primerApellido} ${self.usuario.segundoApellido}`
    )
  },

  getNotaExamen () {
    let nota = 0
    const curso = self.getCursoSnapshot()
    const preguntas = curso.preguntas
    let pendiente = ''
    preguntas.forEach(p => {
      p.porcentajeCalificacion != -1 
      ? nota = nota + p.porcentajeCalificacion
      : pendiente =  ' - Esta es una nota parcial, las preguntas de desarrollo están pendientes de calificación.'
    })
    return ({nota: nota, pendiente: pendiente})
    
  },

   /* Retorna el estado de respuesta de una pregunta en el selector de preguntas en PaginaCurso */
  getPreguntaRespondida (preguntaIndex) {
    const curso = self.getCursoSnapshot()
    const pregunta = curso.preguntas[preguntaIndex]
    const selecciones = pregunta.selecciones
    const  index = selecciones.findIndex(s => s.seleccionada)
    return index > -1 

  },

  getPeriodoDeCursos () {
    return `${self.getFechaInicioClase()} al ${self.getFechaFinClase()} `
  },

  /* retorna true/false si preguntaIndex corresponde a la pregunta seleccionada actualmente */
  /* esta funcion la estoy sustituyendo por getPreguntaSeleccionadaIndex ver CompoentePreguntaPanel1*/
  // getPreguntaSeleccionada(preguntaIndex) {
  //   return self.usuario.preguntaSeleccionadaIndex === preguntaIndex
  // },
  
  getPreguntaSeleccionadaIndex() {
    return self.usuario.preguntaSeleccionadaIndex
  },

  // retorna un snapshot de la pregunta que actualmente se encuentra seleccionada
  getPreguntaSeleccionadaSnapshot() { 
    const curso = self.getCursoSnapshot()
    const preguntas = curso.preguntas
    const pregunta = preguntas[self.usuario.preguntaSeleccionadaIndex]
    return pregunta
  },

  // devuelve un array de las preguntas de la curso seleccionada
  getPreguntasSnapshot() {
    const curso = self.getCursoSnapshot()
    return curso.preguntas
  },

  // Crea reporte de una evalucion
  // Las columnas del reporte son: #pregunta,texto de la pregunta, selecciones escogidas, selecciones correctas, valor (%) y calificacion
  getReporteCurso () {
    const reporte= []
    // const curso = getSnapshot(self.usuario.cursos[self.usuario.cursoSeleccionadoIndex])
    const curso = self.getCursoSnapshot()

    const preguntas = curso.preguntas

    let selecciones = []
    let strCorrectas = ''
    let strSeleccionadas = ''
    
    let respuestaCorrecta = true
    for (let i=0; i < preguntas.length; i++) {
      selecciones = preguntas[i].selecciones
      strCorrectas = ''
      strSeleccionadas = ''

      // este no aplica a preguntas de desarrollo
      if (preguntas[i].tipo === 'multiple' ||  preguntas[i].tipo === 'unica') {
        for (let j = 0; j < selecciones.length ; j++) {
          // if (selecciones[j].seleccionada) {console.log('seleccionada:',j)}
          
          if (selecciones[j].correcta) {strCorrectas = strCorrectas + (j+1) +', '}
          
          if (selecciones[j].seleccionada) { strSeleccionadas = strSeleccionadas + (j+1) + ', ' }
        
          }
        }
        
      reporte.push ({
        numero: i+1,
        pregunta: preguntas[i].pregunta,
        comentarios: preguntas[i].comentarios,
        correctas: strCorrectas,
        seleccionadas: strSeleccionadas,
        valor: preguntas[i].porcentajeValor,
        calificacion: 
          preguntas[i].porcentajeCalificacion === -1 && preguntas[i].tipo === 'desarrollo' 
            ? 'Pendiente' 
            : preguntas[i].porcentajeCalificacion
      })
    }
    return reporte
  },

  // Retorna el valor de la respuesta de la seleccion[0] de una pregunta de desarrollo.
  getRespuesta() {
    const e = self.usuario.cursoSeleccionadoIndex 
    const p = self.usuario.preguntaSeleccionadaIndex
    return self.usuario.cursos[e].preguntas[p].selecciones[0].respuesta
  },

  // Desarrollado para efectos de debugging 
  // Retorna todas las selecciones de todas las preguntas
  getSeleccionesSnapshot() {
    const curso = self.getCursoSnapshot()
    const preguntas = curso.preguntas
    preguntas.forEach(p => {
      p.selecciones.forEach( s => console.log(s.seleccionada) ) })
  },

  getStoreSnapshot () {
    return getSnapshot(self)
  },

  getTiempoRemanenteExamen () {
    // const curso = self.usuario.cursoSeleccionadoIndex
    const curso = self.getCursoSnapshot()
    const minutos = curso.tiempoRemanenteExamen
    const h = parseInt(minutos/60)
    const m = minutos - (h * 60)
    const txt =  h === 0 
      ? `${m} minutos`
      : `${h} horas y ${m} minutos`
    return txt
  },
  
  getTiempoMaximoExamen () {
    const curso = self.getCursoSnapshot()
    return curso.tiempoMaximoExamen
  },

  getTipoDePregunta() {
    const pregunta = this.getPreguntaSeleccionadaSnapshot()
    if (pregunta.tipo === 'multiple')  return 'Selección múltiple'

    if (pregunta.tipo === 'unica')  return 'Selección única' 

    if (pregunta.tipo === 'desarrollo')  return 'Desarrollo' 
  },

  // La uso para efectos de debugging
  getTiposDeSeleccion() {
    const curso = self.getCursoSnapshot()
    curso.preguntas.forEach( (p,i) => console.log(i,p.tipo))
  },

  getUsuarioSnapshot () {
    return getSnapshot(self.usuario)
  },

}));

export default ModeloStore