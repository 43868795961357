/*  
Objetivo: Definicion de modelo de Pregunta
*/ 

import { types, getSnapshot } from 'mobx-state-tree'
import ModeloSeleccion from '../seleccion/ModeloSeleccion'

// Modelo
const ModeloPregunta = types
.model('ModeloPregunta', {
  numero: 1,
  enunciado: '',
  pregunta: '',
  imagen: '',
  comentarios: '', 
  
  tipo: '',  // 'multiple', 'unica', 'desarrollo'

  //Valor en % de la pruegunta
  porcentajeValor: 0,

  // Calificacion asignada. En el cso de preguntas de desarrollo la calificacion es asignada posteriormente por el instructor
  // En el caso de las preguntas de tipo multiple o unica se calcula comparando las selecciones escogidas por el estudiante con las selecciones correctas.
  porcentajeCalificacion: 0, 
  /* 
    Maneja el estado del checkbox en el selector de preguntas en ComponentePreguntasPanel1.
    Este checkbox indica si la pregunta ha sido respondida. Tome en cuenta el tipo de pregunta: multiple o unica. El action para modificar el estado se encuentra ModeloCursos que es la que declara el array de preguntas
  */
  preguntaRespondida: false, 
  
    /* 
    Arreglo con las n respuestas (selecciones) de la pregunta. En el el caso de pregunta tipo desarrollo este arreglo puede quedar vacio.
  */
  selecciones: types.maybe(types.array(ModeloSeleccion)),
  
  
})

export default ModeloPregunta