import React from 'react';
import { Route, Redirect } from 
'react-router-dom';
import { PAGINA_INICIO } from './rutas'  
import { inject, observer,  } from "mobx-react"
import {withRouter} from 'react-router'
import PaginaLogin from '../entidades/usuario/PaginaLogin'
import ComponenteCorreoUsuario from '../entidades/usuario/ComponenteCorreoUsuario';

const RutaPrivada = (props) => {
  const { component: Component, store, ...rest } = props


  return <Route 
    {...rest} render={
      (props) => (
        store.usuario.uid != ''
        ? <Component {...props} />
        : <Redirect to={'/pagina_inicio'}/>
      )
    }
  
  />
}
export default inject('store')(observer(RutaPrivada))