import React from 'react';
import '../estilos/Estilos.css' 

const ErrorValidacion = (props) => {
  return (
    props.error ? <p className = 'msg-error-campo'> { props.error } </p> : null 
  )
}

export default ErrorValidacion
