
//recibe un objeto tipo date y lo formatea en dd/mm/aaaa
const formatearFecha = (objDate) => {
  const meses = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'setiembre',
    'octubre', 
    'noviembre',
    'diciembre'
  ]
  

  const fecha = new Date(objDate)
  const ano = fecha.getFullYear()
  const mes = meses[fecha.getMonth()]
  let hora = ''
  const minutos = (fecha.getMinutes()).toString().padStart(2, '0')
  const dia = (fecha.getDate()).toString().padStart(2,0)

  if (fecha.getHours() > 12) {
    hora = ((fecha.getHours() - 12)).toString().padStart(2,'0') + ':' + minutos + ' PM' 
  } else {
    hora = ((fecha.getHours())).toString().padStart(2,'0')  + ':' + minutos + ' AM' 
  }

  // const hora = (fecha.getHours()).toString().padStart(2,0)


  return(`${dia} de ${mes} de ${ano} - ${hora}`)
}

export default formatearFecha