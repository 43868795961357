/* 
Objetivo
  Despliega Pagina de curso.
*/

import React, {Component}   from 'react';
import TituloPagina from '../../layout/TituloPagina'
import ComponentePreguntasPanel1 from '../pregunta/ComponentePreguntasPanel1'
import ComponentePreguntasPanel2 from '../pregunta/ComponentePreguntasPanel2'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cargando from '../../layout/Cargando'

import { inject, observer,  } from "mobx-react"

import {
  Row, 
  Col, 
  Card, 
  CardText, 
  CardTitle, 
  CardBody,
} from 'mdbreact'

class PaginaCurso extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentWillUnmount () {
    console.log('Componente Pagina Curso unmount')
    this.props.store.setEstatusExamen('FINALIZADO')
  }

  componentDidMount () {

    if (this.props.store.cargando) return null 

    if (this.props.store.getEstatusExamen() === 'FINALIZADO') return null

    // Este intervalo es para enviar un toast a los 5 minutos de finalizar el examen
    this.intervalo5minutos = setInterval (() => {
      const tiempoRemanente = this.props.store.getTiempoRemanenteExamen()
      if (tiempoRemanente <= 5 ) {
        toast.warning ('Quedan 5 minutos o menos para terminar el examen!')
        clearInterval(this.intervalo5minutos)
      }
    },
    
    1000 * 60 ) 

    // Este intervalo es para enviar mensaje final de fin de curso
    this.intervaloFin = setInterval (() => {
      const tiempoRemanente = this.props.store.getTiempoRemanenteExamen()

      if (tiempoRemanente <= 0 ) {
        toast.warning ('El examen ha finalizado, continuar para ver el resultado!')
        clearInterval(this.intervaloFin)
      }
    },
    
    1000 * 60) 

    //Este intervalo es para actualizar el contador en la PaginaCurso
    // Esto esta siendo actualizado en ComponentePreguntasPanel1. Lo elimino de aqui
    // porque estaba descontando cada minuto dos veces = Reducia el tiempo de examen a la mitad
    // Eliminar despues de probar en staging
    // this.contadorIntervalo = setInterval ( () => {
    //   this.props.store.decrementTiempoRemanente()
    //   if (this.props.store.getTiempoRemanenteExamen() <= 0) {
    //     clearInterval(this.contadorIntervalo)
    //     this.props.store.setEstatusExamen('FINALIZADO')
    //   }
    // }, 1000 * 60) 

    this.props.store.setEstatusExamen('EN_EJECUCION')

  }

  render () {
    if (this.props.store.cargando) {
      return (<Cargando/>)
    }
     
    // si ya cargo datos se define el curso seleccionado con base en el codigo de curso enviado como props de PaginaCursos    
    if (!this.props.store.cargando) {
      const cursoIndex = this.props.store.getCursoIndex(this.props.match.params.codigoCurso)
      this.props.store.setCursoSeleccionadoIndex(cursoIndex)
      }  

    // obtiene datos de la curso con base en el param enviado como props por PaginaCursos

    const { 
      nombreCurso,
      
    } = this.props.store.getCursoSnapshot()

    const tiempoRemanente = this.props.store.getTiempoRemanenteExamen()

    return (
      <div>
        
        <div className="col-sm-12 text-center " >
          <h2 style={cssTitulo2} >{`Evaluación del curso ${nombreCurso}`}  </h2>

          {this.props.store.getEstatusExamen() === 'FINALIZADO'
            ? <h2 style={cssTitulo2} >{`Examen concluido satisfactoriamente`}  </h2>
            : <h3 style={cssTitulo3} >{`Tiempo remanente (${this.props.store.getTiempoRemanenteExamen()}`})  </h3>
          }
          
        </div>

        {/* <Card> */}
        <Row className=" row mx-auto">
          <div className="col-2">
            <ComponentePreguntasPanel1/>
          </div>
          <div className="col-10">
            <ComponentePreguntasPanel2/>
          </div> 
      
          <ToastContainer
            hideProgressBar={true}
            newestOnTop={true}
            autoClose={5000}
            />
        </Row>  

      </div>
    )
  }

} // fin PaginaCurso

export default inject('store')(observer(PaginaCurso))

const cssTitulo2 = {
  marginTop: '30px',
  marginBottom: '10px',
  padding: '0px',
  // color: 'black',
  // fontSize: '25px',
  // fontStyle: 'bold',
  textAlign: 'center',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const cssTitulo3 = {
  marginTop: '10px',
  marginBottom: '30px',
  padding: '0px',
  // color: 'black',
  fontSize: '25px',
  // fontStyle: 'bold',
  textAlign: 'center',
  fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}