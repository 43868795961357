/*  
Objetivo: 
  Definicion de modelo de curso
*/ 

import { types, getSnapshot } from 'mobx-state-tree'
import ModeloPregunta from  '../pregunta/ModeloPregunta'  


const ModeloCurso = types
.model('ModeloCurso', { 
  codigoCurso: '',
  cantidadSubsetPreguntas: 0,
  descripcionCurso:  types.optional(types.array(types.string), []),
  
  // Indica si el examen ha sido iniciado o si esta en ejecucion o ya finalizado por parte del estudiante
  estatusExamen: types.enumeration(['NO_INICIADO', 'EN_EJECUCION','FINALIZADO']),
  estatusPeriodoExamen: types.enumeration(['NO_INICIADO', 'ABIERTO','CERRADO']),

  // las fechas las estoy almacenando en FB y en MST como integer y las formateo para el display. Ver utilitarios/formatearFecha
  fechaInicioClase: 0,
  fechaFinClase: 0,
  fechaInicioExamen: 0,
  fechaFinExamen: 0,
  
  imagenCurso: '',
  instrucciones: types.optional(types.array(types.string), []),
  instructorId: '', 
  nombreInstructor: '',
  correoInstructor: '',
  nombreCurso: '',
  notaFinal: 0,

  preguntas: types.maybe(types.array(ModeloPregunta)),
  
  tiempoMaximoExamen: 0,
  tiempoRemanenteExamen: 0, 
})
 
.actions ( self => ({

}))
export default ModeloCurso;
