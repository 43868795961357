/* 
Objetivo:
  Modelo de campo de nombre de usuarrio

Notas:
  No está en uso  
*/

import { types, getSnapshot } from 'mobx-state-tree'

const ModeloMensaje = types
.model('ModeloMensaje', {
  icon: 'comments',
  label: 'Comentarios', 
  name: 'comentario',
  type: 'textarea',
  value: '',
  error: '',
  deshabilitado: false,
  errorRequerido: 'Campo no debe quedar vacío',
  errorLongitud: 'Nombre debe ser de 2 a 400 caracteres',
})

.actions (self => ({
  setValor(value) {
    self.value = value
    self.error = ''
  },

  validar() {
    if (self.value == '') {
      self.error = self.errorRequerido
      return
    }

    if (self.value.length < 2 || self.value.length > 400) {
      self.error = self.errorLongitud
      return
    }
  }

}))

.views (self => ({ 
  getPropiedades () {
    return  getSnapshot(self)
  }

})) //fin views

export default  ModeloMensaje