import React, {Component}  from 'react';
import { inject, observer,  } from "mobx-react"

import { Input, Col, Row, Button } from 'mdbreact'
import Textarea from 'react-textarea-autosize'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6


class ComponenteTipoPregunta extends Component {

  seleccionUnica = (i) => {
    this.props.store.setSeleccionUnica(i)
    this.props.store.saveCursosInFirebase()
  }

  seleccionMultiple = (i)  => {
    this.props.store.setSeleccionMultiple(i)
    this.props.store.saveCursosInFirebase()    
  }

  seleccionDesarrollo = (i, texto) => {
    this.props.store.setSeleccionDesarrollo()
  }

  salvarRespuesta = () => {
    this.props.store.saveCursosInFirebase()
    toast.info ('Respuesta fue salvada')
  }

  render () {
    const {
      tipo,
      selecciones,
    } = this.props.store.getPreguntaSeleccionadaSnapshot()
    
    let comp = null

    if (tipo === 'multiple') {
      comp = selecciones.map ((s,i) => {
        return (
        <Input
          key={s.numero}
          style={cssSeleccion}
          onClick={ () => this.seleccionMultiple(i) }
          
          checked={ this.props.store.getEstatusSeleccion(i) } 
          // disabled={ this.props.store.getEstatusExamen() === 'FINALIZADO'  }
          label= {s.texto} 
          id={`SM${i}`}
          type='checkbox' /> )})}
          
    if (tipo === 'unica') {
      comp = selecciones.map ((s,i) => {
      return (
        <Input
          key={s.numero}
          style={cssSeleccion}
          onClick={ () => this.seleccionUnica(i) }
          checked={ this.props.store.getEstatusSeleccion(i) } 
          // disabled={ this.props.store.getEstatusExamen() === 'FINALIZADO'  }
          label= {s.texto} 
          id={`SU${i}`}
          type='radio' />
    )})}

    if (tipo === 'desarrollo') {
      comp = selecciones.map ((s,i) => {
      return (      
        <Row>
          <Col sm='8' >

             <ReactQuill style={cssDesarrollo} value={this.props.store.getRespuesta(i)}
                  onChange={(text) => this.props.store.setRespuesta(i,text)} />

          </Col>
          <Col sm='4'>
            <Button onClick={this.salvarRespuesta}>
              Salvar
            </Button>

          </Col>

           <ToastContainer hideProgressBar={true}
              newestOnTop={true} autoClose={5000}/>

        </Row>
    )})}

    return comp 
  } //fin render

} // fin class

export default inject('store')(observer(ComponenteTipoPregunta))

// lo aplique a selecciones pero no funciona
const cssSeleccion = {
  color: 'black',
  fontSize: '25px',
  marginLeft: '40px',
  // fontStyle: 'bold',
  // textAlign: 'center',
  // fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const cssDesarrollo = {
  color: 'black',
  fontSize: '25px',
  marginLeft: '50px',
  marginBottom: '50px',
  // fontStyle: 'bold',
  // textAlign: 'center',
  // fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
}

const estiloTextarea = {
  width: '90%', 
  boxSizing: 'border-box',
  marginLeft: '50px',
  marginBottom: '20px',
}