
/* 
Objetivo:
  Panel lateral izquierdo de preguntas.

Props:
  Recibe objeto tipo 'curso' con toda la informacion de la curso. Es pasada por PaginaCurso.
  
Funcionalidad:
  Navegador de preguntas

  Despliega en forma vertical el numero de las pregutas de la curso.

  Muesta el estado de la pregunta(contestada o no contestada)

  Muestra boton de iniciar curso y finalizar curso

  Muestra tiempo restante de curso
*/

import React, {Component}  from 'react';
import '../../estilos/Estilos.css'
import { withRouter, Link } from 'react-router-dom'
import { inject, observer,  } from "mobx-react"
import { PAGINA_INFORMACION} from '../../rutas/rutas'
import {
  Button,
  CardImage, 
  CardBody, 
  CardTitle, 
  CardText, 
  Card, 
  Fa,
  Input,
  ListGroupItem,
  ListGroup,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBInput,
  Modal, 
  ModalBody, 
  ModalHeader, 
  ModalFooter,
} from 'mdbreact' 


class ComponentePreguntasPanel1 extends Component  {

  constructor(props)  {
    super(props)
    this.state = {contadorIntervalo: null, modal: false }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
  
    // si el examen ya finalizo no se inician los intervalos
    if (this.props.store.getEstatusExamen() === 'FINALIZADO') return 

    // cada minuto decrementa tiempoRemanenteExamen 
    // cuando llega a zero reseta el interval y pone examenStatus como FINALIZADO
    this.contadorIntervalo = setInterval ( () => {
      
      // decrementa tiempo remanente y salva el cambio en FB
      this.props.store.decrementTiempoRemanente()
      this.props.store.saveCursosInFirebase()
      
      // si el tiempo se agoto marca la curso como finalizada y la salva en FB
      if (this.props.store.getMinutosRemanenteExamen() <= 0) {
        clearInterval(this.contadorIntervalo)
        this.props.store.setEstatusExamen('FINALIZADO')
        this.props.store.saveCursosInFirebase()
      }
    }, 1000 * 60 )
  // }, 10000 * 6 / 10 ) //lo divido entre 10 para pruebas

    // Marca estatus como en ejecucion y la salva en FB
    this.props.store.setEstatusExamen('EN_EJECUCION')
    this.props.store.saveCursosInFirebase()

  }
  
  // Estudiante finaliza la curso mediante boton
  finalizarExamen = () => {
    clearInterval(this.contadorIntervalo)
    this.props.store.setEstatusExamen('FINALIZADO')
    this.props.store.setCalificaciones()
    this.props.store.saveCursosInFirebase()
    this.toggle()
  }

  verResultados = () => {
    const codigoCurso = this.props.store.getCodigoCurso()
    this.props.history.push(`${PAGINA_INFORMACION}/${codigoCurso}`)
  }

  render () { 
    const columnas = [
      {
        'label': '#',
        'field': 'pregunta',
        'sort': 'asc'
      },
      {
        'label': 'S',
        'field': 'selec',
        'sort': 'asc'
      },
      {
        'label': 'R',
        'field': 'resp',
        'sort': 'asc'
      },

    ]
    const preguntasSnapshot = this.props.store.getPreguntasSnapshot()
    const filas = preguntasSnapshot.map( (p,i) => {
      return({
        // pregunta: p.numero,
        // Usar indice relativo. No usar numero de pregunta de FB
        pregunta: i+1,
        // selector de preguntas
        selec: <MDBInput  
          onClick={ () => this.props.store.setPreguntaSeleccionada(i)  }
          label=' '
          type="radio" 
          checked={ this.props.store.getPreguntaSeleccionadaIndex() === i } 
          disabled={ this.props.store.getEstatusExamen() === 'FINALIZADO'  }
          id={`RA${i}`} />,
  
        // indicador de estatus de pregunta (contestada o pendiente)
        resp:  <MDBInput  
          checked={this.props.store.getPreguntaRespondida(i)}
          label=' '
          type="checkbox" 
          id={`CK${i}`} />,
      })
    }) 

    return (
      <div>
        <Card key={this.props.codigoCurso} >

          {/* <CardBody className='curso-item-card-body-sz'> */}
            <h4 className='preguntas-panel1-titulo'>Preguntas</h4>

            {this.props.store.getEstatusExamen() === 'EN_EJECUCION'
              ? ( <div>
                    <Button style={estiloButton}  size="sm" color="default" onClick={this.toggle}>
                      Finalizar examen 
                    </Button> 
                  </div>)
              : ( <div>
                    <p>Examen concluido</p>
                    <Button style={estiloButton}  block color="primary" onClick={this.verResultados}>
                      Ver resultados 
                    </Button> 
                  </div>)
            }   

          <MDBTable btn small fixed>
            <MDBTableHead columns={columnas} />
            <MDBTableBody rows={filas} />
          </MDBTable>

        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Finalizar examen</ModalHeader>
          <ModalBody>
            <p>Una vez finalizado el examen, éste no se pude reiniciar </p>
            Seguro que desea finalizar el examen ya?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>Cancelar</Button>{' '}
            <Button color="default" onClick={this.finalizarExamen}>Finalizar examen</Button>
          </ModalFooter>
        </Modal>
      </div>
    ) //fin return
  }
} //fin class

export default  withRouter(inject('store')(observer(ComponentePreguntasPanel1)))

const estiloButton = {
  marginLeft: '10px', 
}

